import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import PhoneIcon from "@mui/icons-material/Phone";
import TicketCard from "./TicketCard";
import { Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import { useSelector } from "react-redux";
const antIcon = <LoadingOutlined style={{ fontSize: 115 }} spin />;

const OrderDetails = ({
    tickets,
    current,
    pagination,
    ticketsCount,
    loading,
    show,
    data,
    setClose,
}) => {
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const { t } = useTranslation();
    const lang = useLanguage();
    const [loadingDownloadOrder, setLoadingDownloadOrder] = useState(false);

    const close = () => {
        setClose(false);
    };

    useEffect(() => {
        console.log(data);
        console.log(data.tickets);
        console.log(ticketsCount);
    }, []);

    const cssClasses = [
        "Modal detailsCard",
        show ? "ModalOpen" : "ModalClosed",
    ];
    const downloadLabel = (tickets, type) => {
        setLoadingDownloadOrder(true);
        axiosInstance
            .get(
                "/CreateInvoice/",
                {
                    params: {
                        order: type === "order" ? tickets : null,
                        tickets: type !== "order" ? tickets : null,
                        lang: localStorage.getItem("lang", "en"),
                    },
                    responseType: "blob",
                }
            )
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                console.log(response);
                const link = document.createElement("a");
                link.href = url;
                link.style.display = "none";
                // get file name from content-disposition
                const contentDisposition = response.headers["content-disposition"];
                let fileName = "label.pdf";
                if (contentDisposition) {
                    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = fileNameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, "");
                    }
                }
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
        setLoadingDownloadOrder(false);
    };

    return (
        <>
            <div
                dir={lang === "en" ? "ltr" : "rtl"}
                className={cssClasses.join(" ")}
            >
                {loading ? (
                    <Spin indicator={antIcon} />
                ) : (
                    <>
                        <div className="sectionPadding w-100 d-flex justify-content-start align-items-center gap-3 mb-3">
                            <p>
                                <i onClick={close} className="fas fa-times" />
                            </p>
                            <h3 className="m-0">
                                {lang === "en"
                                    ? ` Order ID#${data.id}`
                                    : `رقم الشحنة ${data.id}`}
                            </h3>
                            <div>
                                <span>{data.type}</span>
                            </div>
                            {/* <i className="fas fa-expand iconPadding"></i> */}
                            <div className="ms-auto d-inline-block" role="button" >
                                <span className="downloadLabel"
                                onClick={() => downloadLabel(data.id, "order")}>
                                    {
                                        loadingDownloadOrder ? (
                                            <Spin indicator={antIcon} />
                                        ) : 
                                    <i className="fas fa-download"></i>
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="costSection">
                            <table className="costTable">
                                <thead>
                                    <tr className="my-2">
                                        <th></th>
                                        <th>{t("shipment")}</th>
                                        <th>{t("products")}</th>
                                        <th>{t("total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="paymentMethod">
                                                {data.payment_method}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {t(cur)}
                                                <span className="priceSpan">
                                                    {data.delivery_fees}
                                                </span>
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {" "}
                                                {t(cur)}{" "}
                                                <span className="priceSpan">
                                                    {data.sub_total}
                                                </span>
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {" "}
                                                {t(cur)}{" "}
                                                <span>
                                                    {parseFloat(
                                                        data.delivery_fees
                                                    ) +
                                                        parseFloat(
                                                            data.sub_total
                                                        )}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="section">
                            <h5>{t("Sender Info")}</h5>
                            <Row>
                                <Col sm="6">
                                    <p>
                                        <i className="fas fa-user userIcons" />
                                        {data.seller.store_name}
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p>
                                        <PhoneIcon className="phoneIcon" />{" "}
                                        {data.seller.user.phone}
                                    </p>
                                </Col>
                                <Col sm="6">
                                    {" "}
                                        {data.seller_address.address && data.seller_address.address !== "N/A" &&
                                            <p>
                                                <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                                            <span>{data.seller_address.address}</span>{" "}
                                            </p>
                                        }
                                        {
                                            data.seller_address.lat && data.seller_address.lng && (
                                                <p>
                                                    <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                                                <a href={`https://www.google.com/maps/search/?api=1&query=${data.seller_address.lat},${data.seller_address.lng}`} target="_blank" rel="noreferrer">
                                                    {t("Show Address on Map")}
                                                </a>
                                                </p>
                                            )
                                        }
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <h5>{t("receiverInfo")}</h5>
                            <Row>
                                <Col sm="6">
                                    <p>
                                        <i className="fas fa-user userIcons" />
                                        {data.client.name}
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p>
                                        <PhoneIcon className="phoneIcon" />{" "}
                                        {data.client.phone}
                                    </p>
                                </Col>
                                <Col sm="6">
                                    {" "}
                                    {
                                        data.client_address.address && data.client_address.address !== "N/A" &&
                                        <p>
                                            <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                                            <span>{data.client_address.address}</span>{" "}
                                        </p>
                                    }
                                    {
                                        data.client_address.lat && data.client_address.lng && (
                                            <p>
                                                <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                                                <a href={`https://www.google.com/maps/search/?api=1&query=${data.client_address.lat},${data.client_address.lng}`} target="_blank" rel="noreferrer">
                                                    {t("Show Address on Map")}
                                                </a>
                                            </p>
                                        )
                                    }
                                </Col>
                                {
                                    data.client.phone_alt && (
                                    <Col sm="6">
                                        <p>
                                            <PhoneIcon className="phoneIcon" />{" "}
                                            01094970654
                                        </p>
                                    </Col>
                                    )
                                }
                            </Row>
                        </div>
                        <div className="section">
                            <Row>
                                <Col dir="ltr" sm={12}>
                                    <div className="d-flex justify-content-start align-items-center gap-3">
                                        <h5 className="m-0">
                                            {t("productDetails")}
                                        </h5>
                                        {/* download label for all tickets */}
                                        <span
                                            className="text-primary"
                                            role="button"
                                            onClick={() =>
                                                downloadLabel(
                                                    tickets.data
                                                        .map(
                                                            (ticket) =>
                                                                ticket.id
                                                        )
                                                        .join(",")
                                                )
                                            }
                                        >
                                            {t("downloadAllLabels")}
                                        </span>
                                    </div>
                                    <Pagination
                                        pageSize="4"
                                        onChange={pagination}
                                        defaultCurrent={current}
                                        total={ticketsCount}
                                        current={current}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                {tickets.data.map((ticket) => {
                                    console.log(ticket);
                                    return (
                                        <Col key={ticket.id} sm={6}>
                                            {" "}
                                            <TicketCard
                                                data={ticket}
                                                downloadLabel={downloadLabel}
                                            />{" "}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ErrorHandler(OrderDetails, axiosInstance);
