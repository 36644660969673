import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from "react";
import { Container, Row, Col } from "react-bootstrap";
import StatusCard from "../../../../components/layout/StatusCard";
import RequestsTable from "../../../../components/layout/RequestsTable";
import { Button, Slider, Spin } from "antd";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../api/axios";
import {
    pickupRequests,
    pickupConfirmed,
    pickupConfirmedArabic,
    pickupRequestsArabic,
} from "../../../../components/layout/tableColumns";
import {
    ConfirmationModal,
    DeleteModal,
    UnSuccessfulRequest,
} from "../../../../components/modals/confirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { plans } from "../../../../global-state/actions/plan";
import { search } from "../../../../global-state/actions/searchAction";
import { selectedState } from "../../../../global-state/actions/stateAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { PaymentAlert } from "../../../../components/modals/confirmationModal";
import { LoadingOutlined } from "@ant-design/icons";
import Tour from "reactour";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";
import useLanguage from "../../../../hooks/useLanguage";
import CardsHeader from "./CardsHeader";
import TableTitle from "./TableTitle";
import TablePro from "../../../../components/tables/TablePro";
import {
    useConfirmedRequestsColumns,
    useUnConfirmedRequestsColumns,
} from "./dashboardColumns";
import DashboardRequestsTable from "./DashboardRequestsTable";
import {Collapse, Select, DatePicker, Form, Input} from "antd";
import { ClearOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
const { RangePicker } = DatePicker;


// const lang = localStorage.getItem("lang");
const steps = [
    {
        selector: ".first-step",
        content: "This is my first Step",
    },
    // ...
];

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

const Dashboard = React.memo(() => {
    const lang = useLanguage();
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    const planDispatch = useDispatch();
    const { t } = useTranslation();
    const paymentPlan = useSelector((state) => state.plan.plan);

    const [showPaymentAlert, setPaymentAlert] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const [count, setCount] = useState(1);
    const [data, setData] = useState([]);
    const [plan, setPlan] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [status, setStatus] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState();
    const [nextState, setNextState] = useState({
        name: lang === "en" ? "Choose State" : "اختر الحالة",
        id: "",
        background: "",
    });
    const [summaryFilters, setSummaryFilters] = useState({
    });
    const [cityFilter, setCityFilter] = useState("");
    const [tableSelection, setTableSelection] = useState([]);
    const [tableSelectedObjects, setTableSelectedObjects] = useState([]);
    const defaultState = useMemo(
        () => ({
            count: 1,
            state: {
                color: "#CEE1FF",
                accent_color: "#00000",
                description: null,
                id: 1,
                title: "Pickup Request",
                headers: [],
                title_ar: "طلب استلام",
            },
        }),
        []
    );
    const [currentState, setCurrentState] = useState(defaultState);
    const currentStateTitle = lang.includes("en")
        ? currentState?.state?.title
        : currentState?.state?.title_ar;
    const unConfirmedStates = {
        pickup: 6,
        return: 10,
        replace: 11,
    };
    const isUnConfirmedRequest = useMemo(
        () =>
            Object.values(unConfirmedStates).includes(
                +(currentState.state?.type ?? 6)
            ),
        [currentState.state?.type]
    );

    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    useEffect(() => {
        if (!showConfirm && !showDelete) {
            getSummary();
            selectState(currentState);
            if (isUnConfirmedRequest) {
                getPlans();
            }
            fetchTableData();
        }
    }, [showConfirm, showDelete, pageSize, summaryFilters]);

    useEffect(() => {
        console.log("SUMMARY FILTERS", summaryFilters);
    }, [summaryFilters]);

    const getPlans = async () => {
        let result = await axiosInstance
            .get("/PlanConsumption/")
            .catch((err) => console.log(err));

        if (result) {
            console.log(result.data[0]);
            planDispatch(plans(result.data[0]));
            setPlan(result.data[0]);
        }
    };

    const getSummary = async () => {
        setLoading(true);
        let result = await axiosInstance.get("/Summary/", {
            params: {
                ...summaryFilters,
            },
        }).catch((err) => {
            console.log(err);
            if (err.response?.status === 401) {
                window.location.reload();
            }
        });

        if (result) {
            console.log(result.data);
            setStatus(result.data);
            console.log(result.data);
        }
        setLoading(false);
    };
    const showConfirmModal = () => {
        setShowConfirm(true);
    };
    const showDeleteModal = () => {
        setShowDelete(true);
    };
    const hideModal = () => {
        setShowConfirm(false);
        setShowDelete(false);
        setNextState({
            ...nextState,
            name: lang === "en" ? "Choose State" : "اختر الحالة",
            id: "",
        });
    };

    async function fetchTableData() {
        setTableLoading(true);
        let result;

        const stateType = +currentState.state.type;

        const extractOrderType = () => {
            console.log({ stateType });

            if (stateType === unConfirmedStates.pickup) return 1;
            else if (stateType === unConfirmedStates.return) return 2;
            else if (stateType === unConfirmedStates.replace) return 3;
            else return undefined;
        };
        if (isUnConfirmedRequest) {
            result = await axiosInstance.get("/FilterOrder/", {
                params: {
                    search,
                    page,
                    page_size: pageSize,
                    state: 3,
                    order_type: extractOrderType(),
                    ...summaryFilters,
                    seller_address__area__city: cityFilter,
                },
            });
        } else {
            result = await axiosInstance.get("/FilterTicket/", {
                params: {
                    search,
                    page,
                    page_size: pageSize,
                    state: currentState.state?.id,
                    ...summaryFilters,
                    order__seller_address__area__city: cityFilter,
                    only_orders: true,
                },
            });
        }
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
        setTableLoading(false);
    }

    function selectState(newState) {
        setCurrentState(newState);
        setTableSelection([]);
        setPage(1);
        setSearch(undefined);
    }

    useEffect(() => {
        fetchTableData();
    }, [currentState, search, page, pageSize, cityFilter]);

    const changeState = (e, v) => {
        console.log(e);
        console.log(v);
        console.log({ v });
        if (v.type === unConfirmedStates.return) {
            history.push("/return_ticket");
        } else {
            setNextState({
                ...nextState,
                name: e,
                id: v.id,
                background: v.style.background,
            });
            showConfirmModal();
        }
    };

    useEffect(() => {
        if (paymentPlan?.orders_count >= paymentPlan?.orders_remaining) {
            setPaymentAlert(true);
        }
        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const closeTour = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <Container>
                <DashboardFilterExpandable filters={summaryFilters} setFilters={setSummaryFilters} />
                <CardsHeader
                    selectState={selectState}
                    summaryFilters={summaryFilters}
                    cityFilter={cityFilter}
                    plan={plan}
                    currentStateTitle={currentStateTitle}
                    loading={loading}
                    status={status}
                />
                <TableTitle currentStateTitle={currentStateTitle} />
                <Row>
                    {["Seller", "Third Party"].includes(user.role) ? null : (
                        <Col className="text-end" sm="12">
                            {/* <button disabled={orders.length === 0} style={{display : "inline-block",border : "none"}} onClick={()=>console.log("clicked")}>
              <Icon  className="mx-2" source={TransferWithinShopifyMajor} color="base" />
              </button> */}
                            {[1, 7, 10].includes(
                                +(currentState.state?.id ?? 1)
                            ) ? (
                                <button
                                    disabled={
                                        tableSelection?.length !== 0
                                            ? false
                                            : true
                                    }
                                    onClick={showDeleteModal}
                                    className="cancel"
                                >
                                    {t("cancelOrder")}
                                </button>
                            ) : null}

                            {!isUnConfirmedRequest ? (
                                currentState.state.accepted_states?.length >
                                0 ? (
                                    <Select
                                        placeholder={t("changeStatus")}
                                        className="changeStatusSelect"
                                        onChange={changeState}
                                        value={nextState.name}
                                        disabled={
                                            tableSelection.length !== 0
                                                ? false
                                                : true
                                        }
                                    >
                                        {/* <Select.Option selected hidden value={0}>Change Status</Select.Option> */}
                                        <>
                                        {
                                            status.filter(state => state.state.id === 12).map(
                                                (state) => (
                                                    <Select.Option
                                                        style={{
                                                            background:
                                                                state.state.color,
                                                            marginBottom: "5px",
                                                            padding: "5px",
                                                            color: "black",
                                                            fontWeight: "bold",
                                                        }}
                                                        value={lang === "en"
                                                            ? state.state.title
                                                            : state.state.title_ar}
                                                        id={state.state.id}
                                                    >
                                                        {lang === "en"
                                                            ? state.state.title
                                                            : state.state.title_ar}
                                                    </Select.Option>

                                                )
                                            )
                                        }
                                        {currentState.state.accepted_states.map(
                                            (state) => {
                                                return (
                                                    <Select.Option
                                                        style={{
                                                            background:
                                                                state.color,
                                                            marginBottom: "5px",
                                                            padding: "5px",
                                                            color: "black",
                                                            fontWeight: "bold",
                                                        }}
                                                        value={
                                                            lang === "en"
                                                                ? state.title
                                                                : state.title_ar
                                                        }
                                                        id={state.id}
                                                    >
                                                        {lang === "en"
                                                            ? state.title
                                                            : state.title_ar}
                                                    </Select.Option>
                                                );
                                            }
                                        )}
                                        </>
                                    </Select>
                                ) : null
                            ) : (
                                <button
                                    disabled={
                                        tableSelection.length !== 0
                                            ? false
                                            : true
                                    }
                                    onClick={showConfirmModal}
                                    className="confirm"
                                >
                                    {t("confirm")}
                                </button>
                            )}
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col sm="12">
                        <DashboardRequestsTable
                            currentState={currentState}
                            isUnConfirmedRequest={isUnConfirmedRequest}
                            data={data}
                            onSearch={setSearch}
                            currentStateTitle={currentStateTitle}
                            onRefresh={() => fetchTableData()}
                            loading={tableLoading}
                            selected={tableSelection}
                            cityFilter={cityFilter}
                            setCityFilter={setCityFilter}
                            setSelected={setTableSelection}
                            tableSelecedObjects={tableSelectedObjects}
                            setTableSelecedObjects={setTableSelectedObjects}
                            pagination={{
                                current: page,
                                total: count,
                                pageSize: pageSize,
                                defaultPageSize: 10,
                                pageSizeOptions: [10, 30, 50, 80, 100],
                                onChange: (page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                },
                            }}
                        />
                    </Col>
                </Row>
                <ConfirmationModal
                    isUnConfirmedRequest={isUnConfirmedRequest}
                    currentState={currentState}
                    nextState={nextState}
                    selectedCount={tableSelection.length}
                    selectedOrders={tableSelection}
                    show={showConfirm}
                    onError={setError}
                    hide={hideModal}
                />
                <DeleteModal
                    currentState={currentState}
                    selectedOrders={tableSelection}
                    nextState={nextState}
                    selectedCount={tableSelection.length}
                    show={showDelete}
                    hide={hideModal}
                />
                <PaymentAlert
                    setShow={setPaymentAlert}
                    show={showPaymentAlert}
                />
                <UnSuccessfulRequest
                    show={error}
                    hide={() => setError(undefined)}
                    title={t("changeStatusConfirmation")}
                    body={error}
                />
            </Container>
            <Tour
                steps={steps}
                isOpen={isOpen}
                onRequestClose={closeTour}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
        </div>
    );
});
export default ErrorHandler(Dashboard, axiosInstance);


const DashboardFilterExpandable = ({ filters, setFilters }) => {
    const { t } = useTranslation();
    const [stores, setStores] = useState([]);
    const [loadingStores, setLoadingStores] = useState(false);
    const [storeSearch, setStoreSearch] = useState("");
    const [selectedStores, setSelectedStores] = useState([]);
    const [orderSearch, setOrderSearch] = useState(filters?.order__id ?? filters?.id);
    const [waybillSearch, setWaybillSearch] = useState(filters?.order__waybill ?? filters?.waybill);
    const [dashboardFilters, setDashboardFilters] = useState({});


    const getStores = async () => {
        setLoadingStores(true);
        let result = await axiosInstance.get(`/FilterSeller/?page_size=20&search=${storeSearch}`).catch((err) => {
            console.log(err);
            if (err.response?.status === 401) {
                window.location.reload();
            }
        });
        if (result) {
            setStores(result.data.data);
        }
        setLoadingStores(false);
    }

    useEffect(() => {
        getStores();
    }, [storeSearch]);
    

    const handleStoreSearch = (search) => {
        setStoreSearch(search);
    }

    const handleStoreSelect = (stores) => {
        console.log("HANDLE STORE SELECT", stores);
        setSelectedStores(stores);
    }

    const handleStoreRemove = (store) => {
        console.log(store, "removed");
        setSelectedStores(selectedStores.filter(s => s.id !== store.id));
    }

    const handleStoreClear = () => {
        setSelectedStores([]);
    }

    const handleFilterChange = (filtersKeys, value) => {
        console.log("HANDLE FILTER CHANGE", filtersKeys, value);
        const newFilters = { ...filters };
        filtersKeys.forEach(filter => {
            newFilters[filter] = value ?? undefined;
        });
        const newDashboardFilters = { ...dashboardFilters };
        filtersKeys.forEach(filter => {
            newDashboardFilters[filter] = value ?? undefined;
        });
        console.log("NEW FILTERS", newFilters, "FILTERS", filtersKeys);
        setFilters(newFilters);
        setDashboardFilters(newDashboardFilters);
    }

    useEffect(() => {
        handleFilterChange(["seller__in", "order__seller__in"], selectedStores.join(","));
    } , [selectedStores]);

    const clearFilters = () => {
        setFilters({});
        setSelectedStores([]);
        setDashboardFilters({});
        setStoreSearch("");
        setOrderSearch("");
        setWaybillSearch("");
    }

    return (
        <Collapse  >
            <style>
                {`
                .ant-picker {
                    width: 100%;
                    }

                .createDateRangePicker.ant-calendar-picker-input.ant-input:hover {
                    border-color: #1f9643e5 !important;
                    outline: 0 !important;
                    -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                    box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                  }
                  
                  .createDateRangePicker:hover {
                    border-color: #1f9643e5 !important;
                  }
                  
                  .createDateRangePicker.ant-picker-focused {
                    border-color: #1f9643e5 !important;
                    outline: 0 !important;
                    -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                    box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
                  }
                  
                  .createDateRangePicker .ant-picker-active-bar {
                    background-color: #1f9643e5 !important;
                  }
                    .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-start
                    .ant-picker-cell-inner {
                    background-color: #27c456 !important;
                  }
                  
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-end
                    .ant-picker-cell-inner {
                    background-color: #27c456 !important;
                  }
                  
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-today
                    .ant-picker-cell-inner::before {
                    border-color: #1f9643e5 !important;
                  }
                  
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
                  .createDateRangePicker
                    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
                  .createDateRangePicker
                    .ant-picker-time-panel-column
                    > li.ant-picker-time-panel-cell-selected
                    .ant-picker-time-panel-cell-inner {
                    background: #e6ffe8 !important;
                  }
                  
                  .createDateRangePicker .ant-btn-primary {
                    background-color: #1f9643e5 !important;
                    border-color: #1f9643e5 !important;
                  }
                  `}
            </style>
            <Panel header={t("Dashboard Filters")} key="1" extra={<GenerateExtra clearFilters={clearFilters} dashboardFilters={dashboardFilters} />}>
                <Form
                    layout="vertical"
                    >
                    <Row className="mb-3">
                        <Col sm="6">
                            <Form.Item label={t("Store Name")}>
                                <Select
                                    showSearch
                                    loading={loadingStores}
                                    value={selectedStores}
                                    onChange={handleStoreSelect}
                                    onSearch={handleStoreSearch}
                                    onClear={handleStoreClear}
                                    allowClear
                                    filterOption={false}
                                    placeholder={t("Select Store")}
                                    mode="multiple"
                                    options={stores.map(store => ({ label: store.store_name, value: store.id }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item label={t("Date")}>
                                <RangePicker
                                    className="createDateRangePicker"
                                    dropdownClassName="createDateRangePicker"
                                    onChange={(dates, dateStrings) => {
                                        console.log(dates, dateStrings, "dates");
                                        handleFilterChange(["create_date__date__range"], dateStrings.join(","));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item label={t("Order ID")}>
                                <Input
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleFilterChange(["order__id", "id"], orderSearch);
                                        }
                                    }}
                                    onChange={(e) => setOrderSearch(e.target.value)}
                                    value={orderSearch}
                                    placeholder={t("Search Order ID")}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item label={t("Waybill Number")}>
                                <Input
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleFilterChange(["order__waybill", "waybill"], waybillSearch);
                                        }
                                    }}
                                    onChange={(e) => setWaybillSearch(e.target.value)}
                                    value={waybillSearch}
                                    placeholder={t("Search Waybill Number")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Collapse>
    )
}


const GenerateExtra = ({clearFilters, dashboardFilters}) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const exportAllTickets = async () => {
        console.log("EXPORT ALL TICKETS");
        setLoading(true);
        let result = await axiosInstance.get("/FilterTicket/", {
            params: {
                export: true,
                ...dashboardFilters,
            },
            responseType: "blob",
        }).catch((err) => {
            console.log(err);
        });
        if (result) {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "all_tickets.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setLoading(false);
    }

    return (
        <>
        <style>
            {`
            .ant-btn-primary {
                background-color: #27c456;
                border-color: #27c456;

            }
            .ant-btn-primary:hover {
                background-color: #27c456;
                border-color: #27c456;
                opacity: 0.8;
            }
            .ant-btn-primary:active {
                background-color: #27c456;
                border-color: #27c456;
            }
            `}
        </style>
            <div className="d-flex justify-content-center align-items-center gap-2">
                <Button
                    type="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        exportAllTickets();
                    }}
                    loading={loading}
                    disabled={loading}
                >
                    {t("Export All Tickets")}
                </Button>
            <ClearOutlined
                onClick={(e) => {
                    e.stopPropagation();
                    clearFilters();
                }} 
                style={{ color: "#27c456" }}
            />
            </div>
        </>
    );
}
