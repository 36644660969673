import { useTranslation } from "react-i18next";
import { Input } from "antd";

export default function SellerEditPersonalInfo({
    isFetched,
    seller,
    setSeller,
    errors,
    setErrors,
}) {
    const { t } = useTranslation();
    const fields = [
        {
            name: "name",
            label: t("name"),
            dataIndex: ["user", "name"],
            required: true,
        },
        {
            name: "store_name",
            label: t("storename"),
            dataIndex: ["store_name"],
            required: true,
        },
        {
            name: "email",
            label: t("email"),
            dataIndex: ["user", "email"],
            required: true,
        },
        {
            name: "phone",
            label: t("phone"),
            dataIndex: ["user", "phone"],
            required: true,
        },
        {
            name: "phone_alt",
            label: t("altPhone"),
            dataIndex: ["user", "phone_alt"],
        },
    ];

    const onChange = (dataIndex, value, name) => {
        if (errors[name]) {
            const errorsCopy = { ...errors };
            delete errorsCopy[name];
            setErrors(errorsCopy);
        }
        const sellerCopy = { ...seller };
        dataIndex.reduce((obj, i, index) => {
            if (index === dataIndex.length - 1) {
                obj[i] = value;
                return;
            }
            return obj?.[i];
        }, sellerCopy);
        setSeller(sellerCopy);
    };
    return (
        <div>
            <h4>{t("personalInfo")}</h4>
            <div className="grid-form">
                {fields.map((field, index) => {
                    const val = field.dataIndex.reduce(
                        (obj, i) => obj?.[i],
                        seller
                    );
                    return (
                        <div key={index}>
                            <label>{field.label}</label>
                            <Input
                                required={field.required}
                                type="text"
                                name={field.name}
                                value={val}
                                onChange={(e) =>
                                    onChange(
                                        field.dataIndex,
                                        e.target.value,
                                        field.name
                                    )
                                }
                            />

                            {errors[field.name] ? (
                                <p className="error">{errors[field.name][0]}</p>
                            ) : (
                                isFetched &&
                                field.required &&
                                !val && (
                                    <p className="error">
                                        {t("requiredField")}
                                    </p>
                                )
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
