import React from "react";
import Dashboard from "../../features/common/dashboard/ui";
import CreateAccount from "../../features/admin/accounts/create/createAccount";
import BulkAccount from "../../components/pages/BulkAccount";
import DeliveryRanges from "../../components/pages/DeliveryRanges";
import CreateRunsheet from "../../components/layout/createRunsheet";
import AllInternals from "../../components/layout/AllInternals";
import EditRunsheetPriorities from "../../components/layout/EditRunsheetPriorities";
import AccountsTabs from "../../components/layout/Accounts/AccountsTabs";
import ViewAssignedRunSheets from "../../components/pages/ViewAssignedRunSheets";
import CloseRunsheet from "../../components/pages/closeRunsheet";
import ActivityLogs from "../../components/pages/ActivityLogs";
import ArchivedRunsheets from "../../components/layout/archivedRunsheets";
import AddTicketsToRunSheet from "../../components/layout/AddTicketsToRunSheet/AddTicketsToRunSheet";
import ViewArchivedRunsheetsOrders from "../../components/pages/viewArchivedRunsheetsOrders";
import Payments from "../../components/pages/payments";
import PaymentSuccess from "../../components/pages/paymentSuccess";
import PaymentFail from "../../components/pages/paymentFail";
import CreateWorkModel from "../../components/pages/CreateWorkModel";
import AllWorkModels from "../../components/pages/AllWorkModels";
import SingleWorkModel from "../../components/pages/SingleWorkModel";
import PricesStep from "../../components/pages/CreateWorkModel/Steps/PricesStep";
import Areas from "../../components/pages/areas";
import BranchesPage from "../../components/pages/Branch";
import Dimentions from "../../components/pages/dimentions";
import Billing from "../../components/pages/settings/billing";
import PriceLists from "../../components/pages/priceLists";
import ArchivedUsers from "../../components/layout/Accounts/ArchivedUsers";
import AccountInfo from "../../components/pages/AccountInfo";
import MoveOrders from "../../components/pages/MoveOrders/MoveOrders";
import PermissionsTabs from "../../components/pages/settings/permissions/permissionsTabs";
import StoreReportsPage from "../../components/pages/Reports/StoreReportsPage";
import BranchReportsPage from "../../components/pages/Reports/BranchReportsPage";
import ReportsTypeSelection from "../../components/pages/Reports/ReportsTypeSelection";
import AllPreferences from "../../components/pages/settings/Preferences/AllPreferences";
import TrakingBoard from "../../components/pages/settings/Preferences/TrakingBoard";
import SystemTheme from "../../components/pages/settings/Preferences/systemTheme";
import TableHeaders from "../../components/pages/settings/Preferences/TableHeaders";
import SellersWallets from "../../components/pages/wallets/sellers";
import SellersOrders from "../../components/pages/wallets/orders";
import SellerOrders from "../../components/pages/wallets/sellerWallet/sellerOrders";
import Settelment from "../../components/pages/wallets/settelment";
import RecievedRequests from "../../components/pages/wallets/recievedRequests";
import SellersRecievedRequests from "../../components/pages/wallets/sellerWallet/sellersRecievedRequests";
import SellerSettelment from "../../components/pages/wallets/sellerWallet/sellerSettelment";
import PaidRequests from "../../components/pages/wallets/paidRequests";
import SellersPaidRequests from "../../components/pages/wallets/sellerWallet/sellersPaidRequests";
import UpdatePassword from "../../components/pages/settings/UpdatePassword";
import LanguageCurrency from "../../components/pages/settings/LanguageCurrency";
import Test from "../../components/pages/test";
import ForgetPassword from "../../components/Login-screens/ForgetPassword";
import Registeration from "../../components/pages/registeration";
import RegisterationAr from "../../components/pages/registerationAr";
import ConfirmationCode from "../../components/Login-screens/ConfirmationCode";
import ResetPassword from "../../components/Login-screens/ResetPassword";
import EditInternal from "../../components/layout/EditInternal";
import ReassignInternal from "../../components/layout/ReassignInternal";
import {
    BalanceMajor,
    CustomersMajor,
    HomeMajor,
    LegalMajor,
    ReportsMajor,
    TransactionFeeDollarMajor,
    TransferWithinShopifyMajor,
    WholesaleMajor,
    ProductsMajor,
    KeyMajor,
    HashtagMajor,
    DraftOrdersMajor
} from "@shopify/polaris-icons";
import orders, { useAdminOrdersRoute, useSellerOrdersRoute } from "./orders";
import settings from "./settings";
import { useTranslation } from "react-i18next";
import useOrdersRoutes from "./orders";
import useSettingsRoutes from "./settings";
import Login from "../../components/Login-screens/Login";
import CreateProduct from "../../features/seller/products/ui/CreateProduct";
import AllProducts from "../../features/seller/products/ui/AllProducts";
import AllTiersPage from "../../features/admin/tiers/ui/all-tiers/all-tiers";
import EditTier from "../../features/admin/tiers/ui/edit-tier/edit-tier";
import SellerAccountInfo from "../../components/pages/seller-account-info/seller-account-info";
import OrderUnAuth from "../../components/pages/OrderUnAuth";
import ThirdPartyInfo from "../../components/pages/ThirdPartyInfo";
import ThirdPartyKeys from "../../components/pages/ThirdPartyKeys";
import Webhooks from "../../components/pages/Webhooks";
import AllSmsTemplates from "../../components/pages/SmsTemplates";
import SmsTemplateDetails from "../../components/pages/SmsTemplates/SmsTemplate";


export function useThirdPartyRoutes() {
    const { t } = useTranslation();
    return {
        common: {
            "/dashboard": {
                component: Dashboard,
                isOpen: true,
                title: t("sideMenu.dashboard"),
                icon: HomeMajor,
            },
            "/api_keys": {
                component: ThirdPartyKeys,
                title: t("sideMenu.apiKeys"),
                icon: KeyMajor,
            },
            "/webhooks": {
                component: Webhooks,
                title: t("sideMenu.webhooks"),
                icon: HashtagMajor,
            },
        },
        settings: useSettingsRoutes(),
        unAuthed: {
            "/third_party_login": {
                component: Login,
            },
            "/forget_password": {
                component: ForgetPassword,
            },
            "/registeration": {
                component: Registeration,
            },
            "/registerationar": {
                component: RegisterationAr,
            },
            "/confirm": {
                component: ConfirmationCode,
            },
            "/reset_password": {
                component: ResetPassword,
            },
        },
    };
}

export function useAdminRoutes() {
    const { t } = useTranslation();
    return {
        common: {
            "/dashboard": {
                component: Dashboard,
                title: t("sideMenu.dashboard"),
                icon: HomeMajor,
            },
            "/delivery_range": {
                component: DeliveryRanges,
            },
        },
        accounts: {
            meta: {
                title: t("sideMenu.accounts"),
                icon: CustomersMajor,
            },
            "/create_account": {
                component: CreateAccount,
                title: t("sideMenu.createNewAcc"),
                rules: ["Can add user"],
            },
            "/create_bulk_accounts": {
                component: BulkAccount,
                title: t("sideMenu.createBulk"),
                rules: ["Can add user"],
            },
            "/all_accounts": {
                component: AccountsTabs,
                title: t("sideMenu.allAccounts"),
                rules: ["Can view user"],
            },
            "/archived_users": {
                component: ArchivedUsers,
                title: t("sideMenu.archived"),
                rules: ["Can view user"],
            },
            "/user_details/seller/:id": {
                component: SellerAccountInfo,
                rules: ["Can view user"],
            },
            "/user_details/thirdParty/:id": {
                component: ThirdPartyInfo,
                rules: ["Can view user"],
            },
            "/user_details/:type/:id": {
                component: AccountInfo,
                rules: ["Can view user"],
            },
            "/permissions": {
                component: PermissionsTabs,
                isOpen: true,
            },
        },
        orders: { ...useOrdersRoutes(), ...useAdminOrdersRoute() },
        runsheets: {
            meta: {
                title: t("sideMenu.runsheets"),
                icon: LegalMajor,
            },
            "/create_runsheet": {
                component: CreateRunsheet,
                title: t("sideMenu.createNewRunsheet"),
                rules: ["Can add runsheet"],
            },
            "/reassign_runsheet/:id": {
                component: () => <CreateRunsheet isReAssigned={true} />,
                rules: ["Can change runsheet"],
            },
            "/assigned_rs": {
                component: ViewAssignedRunSheets,
                title: t("sideMenu.assinedRunsheet"),
                rules: ["Can view runsheet"],
            },
            "/archived_runsheets": {
                component: ArchivedRunsheets,
                title: t("sideMenu.archived"),
                rules: ["Can view runsheet"],
            },
            "/edit_runsheet_priorities/:id/:type": {
                component: EditRunsheetPriorities,
                rules: ["Can change runsheet"],
            },
            "/add_to_runsheet/:id": {
                component: AddTicketsToRunSheet,
                rules: ["Can change runsheet"],
            },
            "/view_runsheet_orders": {
                component: ViewArchivedRunsheetsOrders,
                rules: ["Can view runsheet"],
            },
            "/closeRunsheet/:id": {
                component: CloseRunsheet,
                rules: ["Can change runsheet"],
            },
        },
        internalRunsheets: {
            meta: {
                title: t("sideMenu.internalRunSheet"),
                icon: TransferWithinShopifyMajor,
            },
            "/move_orders": {
                component: MoveOrders,
                title: t("sideMenu.createInternal"),
                rules: ["Can add runsheet"],
            },
            "/all_internals": {
                component: AllInternals,
                title: t("sideMenu.allInternals"),
                rules: ["Can view runsheet"],
            },
            "/edit_internal/:id/:oper": {
                component: EditInternal,
                title: t("Edit Internal Runsheet"),
                rules: ["Can change runsheet"],
            },
            "/reassign_internal/:id": {
                component: ReassignInternal,
                title: t("Reassign Internal Runsheet"),
                rules: ["Can change runsheet"],
            },

        },
        tiers: {
            meta: {
                title: t("sideMenu.tiers"),
                icon: TransactionFeeDollarMajor,
            },
            "/tiers": {
                component: AllTiersPage,
                title: t("sideMenu.tiers"),
                icon: TransactionFeeDollarMajor,
                rules: [
                    "Can view tier",
                    "Can add tier",
                    "Can change tier",
                    "Can delete tier",
                ],
            },
            "/tier/:id": {
                component: EditTier,
                rules: [
                    "Can view tier",
                    "Can add tier",
                    "Can change tier",
                    "Can delete tier",
                ],
            },
        },
        workModels: {
            meta: {
                icon: TransactionFeeDollarMajor,
                title: t("sideMenu.workModels"),
            },
            "/create_work_model": {
                component: CreateWorkModel,
                title: t("sideMenu.createWorkModel"),
                rules: [113],
            },
            "/all_work_models": {
                component: AllWorkModels,
                title: t("sideMenu.all_work_models"),
                rules: [116, 114, 115],
            },
            "/view_work_model/:id": {
                component: SingleWorkModel,
                rules: [116, 114, 115],
            },
            "/copy_work_model/:id": {
                component: SingleWorkModel,
                rules: [113, 116, 115],
            },
            "/edit_diemention_work_model/:id": {
                component: PricesStep,
                rules: [113, 116, 114, 115],
            },
        },
        wallet: {
            meta: {
                title: t("sideMenu.wallet"),
                icon: BalanceMajor,
            },
            "/sellers_wallets": {
                component: SellersWallets,
                title: t("sideMenu.sellers"),
                rules: [
                    "Can view wallet",
                    "Can add wallet",
                    "Can change wallet",
                    "Can delete wallet",
                ],
            },
            "/pay_requests": {
                component: PaidRequests,
                title: t("sideMenu.requestsToPay"),
                rules: [
                    "Can view wallet",
                    "Can add wallet",
                    "Can change wallet",
                    "Can delete wallet",
                ],
            },
            "/recieved_requests": {
                component: RecievedRequests,
                title: t("sideMenu.requestsToRecieve"),
                rules: [
                    "Can view wallet",
                    "Can add wallet",
                    "Can change wallet",
                    "Can delete wallet",
                ],
            },
            "/payments": {
                component: Payments,
            },
            "/payment_success": {
                component: PaymentSuccess,
            },
            "/payment_fail": {
                component: PaymentFail,
            },
            "/billing": {
                component: Billing,
            },
            "/price_lists": {
                component: PriceLists,
            },
            "/seller_orders": {
                component: SellerOrders,
                title: t("orders"),
            },
            "/seller_pay": {
                component: SellersPaidRequests,
                title: t("sideMenu.requestsToPay"),
            },
            "/seller_recieve": {
                component: SellersRecievedRequests,
                title: t("sideMenu.confirmReceive"),
            },
            "/sellers_orders/:id": {
                component: SellersOrders,
            },
            "/seller_settelment/:id": {
                component: SellerSettelment,
            },
            "/settelment/:id": {
                component: Settelment,
            },
        },
        reports: {
            "/select_reports": {
                component: ReportsTypeSelection,
                title: t("sideMenu.reports"),
                icon: ReportsMajor,
                rules: ["Can view report"],
            },
            "/branch_reports": {
                component: BranchReportsPage,
                rules: ["Can view report"],
            },
            "/store_reports": {
                component: StoreReportsPage,
                rules: ["Can view report"],
            },
        },
        smsTemplates: {
            meta: {
                title: t("sideMenu.smsTemplates"),
                icon: DraftOrdersMajor,
            },
            "/sms_templates": {
                component: AllSmsTemplates,
                title: t("sideMenu.smsTemplates"),
                icon: DraftOrdersMajor,
                rules: [],
            },
            "/sms_templates/:id": {
                component: SmsTemplateDetails,
                rules: [],
            },
        },
        settings: useSettingsRoutes(),
        unused: {
            "/logs": {
                component: ActivityLogs,
            },
        },
        unAuthed: {
            "/admin_forget_password": {
                component: ForgetPassword,
            },
            "/registeration": {
                component: Registeration,
            },
            "/registerationar": {
                component: RegisterationAr,
            },
            "/admin_confirm": {
                component: ConfirmationCode,
            },
            "/admin_reset_password": {
                component: ResetPassword,
            },
            "/login": {
                component: Login,
            },
            "/track/:waybill": {
                component: OrderUnAuth,
            },
        },
    };
}

export function useSellerRoutes(isThirdParty=false) {
    const { t } = useTranslation();
    const routes = {
        common: {
            "/dashboard": {
                component: Dashboard,
                isOpen: true,
                title: t("sideMenu.dashboard"),
                icon: HomeMajor,
            },
        },
        products: {
            meta: {
                title: t("sideMenu.products"),
                icon: ProductsMajor,
            },
            "/add_product": {
                component: CreateProduct,
                title: t("sideMenu.createProduct"),
            },
            "/products": {
                component: AllProducts,
                title: t("sideMenu.allProducts"),
            },
        },
        orders: {
            ...useOrdersRoutes(),
            ...useSellerOrdersRoute(),
            meta: {
                title: t("orders"),
                icon: WholesaleMajor,
            },
        },
        wallet: {
            meta: {
                title: t("sideMenu.wallet"),
                icon: BalanceMajor,
            },
            "/seller_orders": {
                component: SellerOrders,
                title: t("orders"),
            },
            "/seller_pay": {
                component: SellersPaidRequests,
                title: t("sideMenu.requestsToPay"),
            },
            "/seller_recieve": {
                component: SellersRecievedRequests,
                title: t("sideMenu.confirmReceive"),
            },
            "/sellers_orders/:id": {
                component: SellersOrders,
            },
            "/recieved_requests": {
                component: RecievedRequests,
            },
            "/seller_settelment/:id": {
                component: SellerSettelment,
            },
            "/sellers_wallets": {
                component: SellersWallets,
                title: t("sideMenu.sellers"),
            },
            "/pay_requests": {
                component: PaidRequests,
                title: t("sideMenu.requestsToPay"),
            },
            "/payments": {
                component: Payments,
            },
            "/payment_success": {
                component: PaymentSuccess,
            },
            "/payment_fail": {
                component: PaymentFail,
            },
            "/billing": {
                component: Billing,
            },
            "/price_lists": {
                component: PriceLists,
            },
            "/settelment/:id": {
                component: Settelment,
            },
        },
        settings: useSettingsRoutes(),
        unAuthed: {
            "/seller-login": {
                component: Login,
            },
            "/forget_password": {
                component: ForgetPassword,
            },
            "/registeration": {
                component: Registeration,
            },
            "/registerationar": {
                component: RegisterationAr,
            },
            "/confirm": {
                component: ConfirmationCode,
            },
            "/reset_password": {
                component: ResetPassword,
            },
        },
    };
    if(isThirdParty){
        routes.common = {
            ...routes.common,
            "/api_keys": {
                component: ThirdPartyKeys,
                title: t("sideMenu.apiKeys"),
                icon: KeyMajor,
            },
            "/webhooks": {
                component: Webhooks,
                title: t("sideMenu.webhooks"),
                icon: HashtagMajor,
            },
        }
    };
    return routes;

}
